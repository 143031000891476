import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {clearEmptyKeys} from "../utils/common";
import {getQueryString} from "../utils/filterUtils";

const useSearchMove = (type) => {
  const history = useHistory();
  const selectedFilterData = useSelector(
    (state) => state.selectedFilterData.value,
  );
  const moveToFilter = () => {
    const query = getQueryString(selectedFilterData);

    history.push({
      // pathname: '/catalog',
      pathname: `/${type}`, // /catalog, /commerce
      search: "?" + query, // query string
      state: {
        // location state
        update: true,
      },
    });
  };
  const moveToFilterOverridden = (overridden = []) => {
    const cloneObj = { ...selectedFilterData };
    if (cloneObj.hasOwnProperty("objectId")) {
      delete cloneObj.objectId;
    }
    const mergedResult= Object.assign({}, cloneObj, overridden);

    const cloneSelectedFilterData = clearEmptyKeys(mergedResult);
    const query = getQueryString(cloneSelectedFilterData);
    history.push({
      pathname: `/${type}`, // /catalog, /commerce
      search: "?" + query, // query string
      state: {
        // location state
        update: true,
      },
    });
  };
  const moveToFilterOverriddenWithUrlData = (overridden = []) => {

    const queryString = new URL(window.location.href).search;
    const queryParams = Object.fromEntries(new URLSearchParams(queryString));
    const cloneObj = { ...selectedFilterData,...queryParams };
    if (cloneObj.hasOwnProperty("objectId")) {
      delete cloneObj.objectId;
    }
    const mergedResult= Object.assign({}, cloneObj, overridden);

    const cloneSelectedFilterData = clearEmptyKeys(mergedResult);
    const query = getQueryString(cloneSelectedFilterData);
    history.push({
      pathname: `/${type}`, // /catalog, /commerce
      search: "?" + query, // query string
      state: {
        // location state
        update: true,
      },
    });
  };


  const moveToFilterPaginate = (page ) => {
    const state = { update: true, isPaginated: true};
    const queryString = new URL(window.location.href).search;
    const queryParams = Object.fromEntries(new URLSearchParams(queryString));
    delete queryParams.page;
    if (page && page > 1){
      queryParams.page=page
    }
    const query = getQueryString(queryParams);



    history.push({
      pathname: `/${type}`, // /catalog, /commerce
      search: "?" + query, // query string
      state:state
    });
  };
  const moveToPage = (page) => {
    const state = { update: true};

    history.push({
      pathname: `/${page}`, // /catalog, /commerce
      state: state,
      // state: {
      //   // location state
      //   update: true,
      // },
    });
  };
  return { moveToFilter, moveToFilterPaginate, moveToFilterOverridden,moveToFilterOverriddenWithUrlData,moveToPage };
};
export default useSearchMove;
