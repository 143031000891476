import React, {useContext, useEffect, useState} from "react";
import AppHeader from "/src/components/AppHeader";
import BreadCrumbs from "/src/components/ui/BreadCrumbs";

import Pagination from "/src/components/ui/Pagination";
import HomePageBestDeals from "/src/components/HomePageBestDeals";
import AppFooter from "/src/components/AppFooter";
import GoogleMapComponent from "/src/components/GoogleMapComponent/GoogleMapComponent";
import MobileFilterElementsWrap from "/src/components/MobileComponents/MobileFilterElementsWrap";
import { useDispatch, useSelector } from "react-redux";
import { putObjects } from "/src/store/reducers/objects";
import { useLocation } from "react-router-dom";
import { setFilter } from "/src/store/reducers/filter";

import { setMass } from "../../store/reducers/selectedFilterData";
import { useTranslation } from "react-i18next";
import { addTypeObjectToQueryByPathName } from "../../utils/api";
import { filterContainer } from "../../containers/filterContainer";
import SearchResultsItem from "../../components/SearchResultsItem";
import OurChannels from "../../components/common/OurChannels";

import {
  CatalogPlaceIcon,
  CatalogPlotsIcon,
} from "../../components/vector/CatalogsIcons";
import QuestionBanner from "../../components/common/QuestionBanner";
import PlotsCatalogFilter from "../../components/CatalogFiltersComponents/PlotsCatalogFilter";
import PlotsCatalogAdvancedFilter from "../../components/CatalogFiltersComponents/PlotsCatalogAdvancedFilter";
import {scrollTopAction} from "../../utils/common";
import {MobileFilterNavigationContext} from "../../providers/MobileFilterNavigationProvider";
import {compose} from "@reduxjs/toolkit";
import withMobileFilterNavigationProvider from "../../decorators/withMobileFilterNavigationProvider";

const PlotsCatalog = (props) => {
  const { stateMobileFilterNavigation, dispatchMobileFilterNavigation,refIsPaginated,refShowAdvancedFilterMobile,changeNavigationState,changeNavigationFilterState,callbackAfterClickBurgerMenu,callbackIsPaginated,handlerShowProposals,openAdvancedFilterMobile  } = useContext(MobileFilterNavigationContext)
  const [modelType] = useState("plots");
  const [selectedPrimaryParamsList] = useState([
    {
      title: "Ділянки",
      value: "new",
      icon: <CatalogPlotsIcon />,
    },
    {
      title: "Одеса",
      value: "odessa",
      icon: <CatalogPlaceIcon />,
    },
  ]);
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [showResults, setShowResults] = useState(true);
  const [showAdvancedFilterMobile, setShowAdvancedFilterMobile] =
    useState(false);
  const [hasReset, setHasReset] = useState(0);
  const { t, i18n } = useTranslation();

  const location = useLocation();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.objects.data.data);
  const meta = useSelector((state) => state.objects.data.meta);

  const handlerReset = () => {
    setHasReset(Math.random());
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlParams);
    dispatch(setMass(params));
  }, []);

  useEffect(() => {
    // todo stop here double load location
    const search = addTypeObjectToQueryByPathName(location, modelType);
    props.loadPlaces(search).then((data) => {
      dispatch(putObjects(data));
    });
    props.loadFilterData(`?type=${modelType}`).then((data) => {
      setShowResults(true);
      setShowAdvancedFilterMobile(false);
      dispatch(setFilter(data));
    });
    scrollTopAction();
  }, [location, i18n.language]);

  return (
    <div className="catalog-page">
      <AppHeader
        setShowFilterMobile={openAdvancedFilterMobile}
        showMobileFilter={true}
        isActiveMobileIcon={stateMobileFilterNavigation.isOpenMobileResult}
        callbackAfterClick={callbackAfterClickBurgerMenu}
        modelType={modelType}
      />
      <div className="catalog-page--layout">
        <div className="filter--cell mobile-hidden">
          <div className="filter--cell--layout">
            <BreadCrumbs
              linkChain={[
                {
                  title: "Головна",
                  path: "/",
                },
                {
                  title: "Ділянки",
                  path: "#",
                },
                {
                  title: "Ділянки",
                  path: "",
                },
              ]}
            />

            <div className="filter--wrap">
              <div className="filter-head">
                <ul className="selected-params--list">
                  <h2>{t("Каталог ділянок")}</h2>

                  {selectedPrimaryParamsList.map((obj, i) => {
                    return (
                      <li key={`${i}_paged`} className="selected-params--item">
                        {obj.icon && (
                          <span className="icon-wrap">{obj.icon}</span>
                        )}

                        {t(obj.title)}
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="filter-form--wrap">
                <PlotsCatalogFilter
                  showAdvancedFilter={showAdvancedFilter}
                  setShowAdvancedFilter={setShowAdvancedFilter}
                  hasReset={hasReset}
                  type={modelType}
                />
              </div>
            </div>

            <div className="search-results-wrap">
              <div className="search-results-head">
                <div className="results-count">
                  {meta.total} {t("результатів")}
                </div>

                <div className="sorting-cell">{/*<SortingDrop />*/}</div>
              </div>

              <div className="search-results-list">
                {data.map((e) => {
                  return <SearchResultsItem model={e} key={e.id} />;
                })}
              </div>

              <Pagination meta={meta} type={modelType} />
            </div>

            <OurChannels />
          </div>
        </div>

        <div className="map--cell">
          {showAdvancedFilter && (
            <PlotsCatalogAdvancedFilter
              onCloseRequest={setShowAdvancedFilter.bind(null, false)}
              onReset={handlerReset}
              hasReset={hasReset}
              type={modelType}
            />
          )}

          <div className="map--wrap">
            <GoogleMapComponent options={data} onDragEnd={(map) => {}} />
          </div>
        </div>

        <div className="desctop-hidden evggplot">
          {stateMobileFilterNavigation.showResults && (
            <MobileFilterElementsWrap
              title={ meta.total ? `${meta.total}  ${t("результатів")}` :''}
              loading={false}
              openLayer={stateMobileFilterNavigation.isOpenMobileResult}
              callbackClick={changeNavigationState}
            >
              <div className="search-results-wrap">
                <div className="search-results-list">
                  {data.map((e) => {
                    return <SearchResultsItem model={e} key={e.id} />;
                  })}
                </div>

                <Pagination meta={meta} type={modelType} callBack={callbackIsPaginated}  />
              </div>

              <QuestionBanner type={modelType} />

              <AppFooter />
            </MobileFilterElementsWrap>
          )}

          {refShowAdvancedFilterMobile.current && (
            <MobileFilterElementsWrap
              title={t("Фільтри")}
              view={2}
              openLayer={refShowAdvancedFilterMobile.current}
              loading={false}
              callbackClick={changeNavigationFilterState}
            >
              <PlotsCatalogAdvancedFilter
                onReset={handlerReset}
                hasReset={hasReset}
                callbackShowProposals={handlerShowProposals}
                type={modelType}
              />
            </MobileFilterElementsWrap>
          )}
        </div>
      </div>

      <div className="mobile-hidden">
        <QuestionBanner type={modelType} />

        <HomePageBestDeals />

        <AppFooter />
      </div>
    </div>
  );
};
export default compose(filterContainer, withMobileFilterNavigationProvider)(PlotsCatalog)
