import React, {useContext, useEffect, useRef, useState} from "react";

import BreadCrumbs from "/src/components/ui/BreadCrumbs";
import SearchResultsItem from "/src/components/SearchResultsItem";
import Pagination from "../../components/ui/Pagination";
import HomePageBestDeals from "/src/components/HomePageBestDeals";
import AppFooter from "/src/components/AppFooter";
import GoogleMapComponent from "/src/components/GoogleMapComponent/GoogleMapComponent";
import MobileFilterElementsWrap from "/src/components/MobileComponents/MobileFilterElementsWrap";
import { useDispatch, useSelector } from "react-redux";
import { putObjects } from "/src/store/reducers/objects";
import { useLocation } from "react-router-dom";
import { filterContainer } from "/src/containers/filterContainer";
import { setFilter } from "/src/store/reducers/filter";
import { setMass } from "../../store/reducers/selectedFilterData";
import { useTranslation } from "react-i18next";
import { addTypeObjectToQueryByPathName } from "../../utils/api";
import OurChannels from "../../components/common/OurChannels";
import CatalogAdvancedFilter from "../../components/CatalogFiltersComponents/CatalogAdvancedFillter";
import CatalogFilter from "../../components/CatalogFiltersComponents/CatalogFilter";
import {
  CatalogApartmentIcon,
  CatalogPlaceIcon,
} from "../../components/vector/CatalogsIcons";
import QuestionBanner from "../../components/common/QuestionBanner";
import {scrollTopAction} from "../../utils/common";
import { compose } from '@reduxjs/toolkit'
import withMobileFilterNavigationProvider from "../../decorators/withMobileFilterNavigationProvider";
import {MobileFilterNavigationContext} from "../../providers/MobileFilterNavigationProvider";
import AppHeader from "../../components/AppHeader";

const RoomCatalog = (props) => {
  const { stateMobileFilterNavigation, dispatchMobileFilterNavigation,refIsPaginated,refShowAdvancedFilterMobile,changeNavigationState,changeNavigationFilterState,callbackAfterClickBurgerMenu,callbackIsPaginated,handlerShowProposals,openAdvancedFilterMobile  } = useContext(MobileFilterNavigationContext)
  const [modelType] = useState("apartment");
  const [selectedPrimaryParamsList] = useState([
    { title: "Квартири", value: modelType, icon: <CatalogApartmentIcon /> },
    { title: "Одеса", value: "odessa", icon: <CatalogPlaceIcon /> },
  ]);
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [hasReset, setHasReset] = useState(0);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.objects.data.data);
  const meta = useSelector((state) => state.objects.data.meta);


  //read from url anc convert to store selectedFilterData
  //     value: {
  //         city:33,
  //             // district:11,
  //             // district:8,
  //             price,
  //             rooms,
  //             populars,
  //             floors,
  //             numberOfStoreys,
  //             buildingType,
  //             heating,
  //             walls,
  //             repair,
  //     },

  const handlerReset = () => {
    setHasReset(Math.random());
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlParams);
    dispatch(setMass(params));
  }, []);

  useEffect(() => {

    const search = addTypeObjectToQueryByPathName(location, modelType);
    props.loadPlaces(search).then((data) => {
      dispatch(putObjects(data));
    });
    props.loadFilterData(`?type=${modelType}`).then((data) => {
      dispatch(setFilter(data));
    });
    scrollTopAction();
  }, [location, i18n.language]);

  return (
    <div className="catalog-page">
      <AppHeader
        setShowFilterMobile={openAdvancedFilterMobile}
        showMobileFilter={true}
        isActiveMobileIcon={stateMobileFilterNavigation.isOpenMobileResult}
        callbackAfterClick={callbackAfterClickBurgerMenu}
        modelType={"catalog"}
      />

      <div className="catalog-page--layout">
        <div className="filter--cell mobile-hidden">
          <div className="filter--cell--layout">
            <BreadCrumbs
              linkChain={[
                {
                  title: "Головна",
                  path: "/",
                },
                // {
                //   title: "Придбати",
                //   path: "#",
                // },
                {
                  title: "Квартири вторинний ринок",
                  path: "#",
                },
              ]}
            />

            <div className="filter--wrap">
              <div className="filter-head">
                <ul className="selected-params--list">
                  <h2>{t("Каталог об'єктів")}</h2>

                  {selectedPrimaryParamsList.map((obj, i) => {
                    return (
                      <li key={`${i}_paged`} className="selected-params--item">
                        {obj.icon && (
                          <span className="icon-wrap">{obj.icon}</span>
                        )}

                        {t(obj.title)}
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="filter-form--wrap">
                <CatalogFilter
                  showAdvancedFilter={showAdvancedFilter}
                  setShowAdvancedFilter={setShowAdvancedFilter}
                  hasReset={hasReset}
                />
              </div>
            </div>

            <div className="search-results-wrap">
              <div className="search-results-head">
                <div className="results-count">
                  {meta.total} {t("результатів")}
                </div>

                <div className="sorting-cell">{/*<SortingDrop />*/}</div>
              </div>

              <div className="search-results-list">
                {data.map((e) => {
                  return <SearchResultsItem model={e} key={e.id} />;
                })}
              </div>

              <Pagination meta={meta} type={"catalog"} />
            </div>

            <OurChannels />
          </div>
        </div>

        <div className="map--cell">
          {showAdvancedFilter && (
            <CatalogAdvancedFilter
              onCloseRequest={setShowAdvancedFilter.bind(null, false)}
              onReset={handlerReset}
              hasReset={hasReset}
              type={"catalog"}
            />
          )}

          <div className="map--wrap">
            <GoogleMapComponent options={data} onDragEnd={(map) => {}} />
          </div>
        </div>

        <div className="desctop-hidden drft">
          {stateMobileFilterNavigation.showResults && (
            <MobileFilterElementsWrap
              title={ meta.total ? `${meta.total}  ${t("результатів")}` :''}
              loading={false}
              openLayer={stateMobileFilterNavigation.isOpenMobileResult}
              callbackClick={changeNavigationState}
            >
              <div className="search-results-wrap">
                <div className="search-results-list">
                  {data.map((e) => {
                    return <SearchResultsItem model={e} key={e.id} />;
                  })}
                </div>

                <Pagination meta={meta} type={"catalog"} callBack={callbackIsPaginated}  />
              </div>

              <QuestionBanner type={modelType} />

              <AppFooter />
            </MobileFilterElementsWrap>
          )}

          {refShowAdvancedFilterMobile.current && (
            <MobileFilterElementsWrap
              title={t("Фільтри")}
              view={2}
              openLayer={refShowAdvancedFilterMobile.current}
              loading={false}
              callbackClick={changeNavigationFilterState}
            >
              <CatalogAdvancedFilter
                onReset={handlerReset}
                hasReset={hasReset}
                callbackShowProposals={handlerShowProposals}
                type={"catalog"}
              />
            </MobileFilterElementsWrap>
          )}
        </div>
      </div>

      <div className="mobile-hidden">
        <QuestionBanner type={modelType} />
        <HomePageBestDeals />

        <AppFooter />
      </div>
    </div>
  );
};

export default compose(filterContainer, withMobileFilterNavigationProvider)(RoomCatalog)
