import React, { useEffect, useState } from "react";

import {useSelector } from "react-redux";
import useSearchMove from "../../hooks/useSearchMove";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {useLocation} from "react-router-dom";
import {useQuery} from "../../hooks/useQuery";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {withStyles} from "@material-ui/core/styles";
import Autocomplete from "@mui/material/Autocomplete";
import {OutlinedInput} from "@mui/material";


const CustomSelect = withStyles((theme)=> ({
  root: {
    "& .MuiSelect-select": {
      padding: "10px", // Custom padding
      paddingRight  : "10px!important", // Custom padding
      paddingLeft  : "10px", // Custom padding
      fontSize: "0.85rem", // Custom font-size for select-option
    },
    "& .MuiSvgIcon-root": {
      display: "none",
    },
    ".MuiSelect-root":{
      minWidth: '90%'
    }
  },

}))(Select);
const CustomMenuProps = {
  PaperProps: {
    sx: {
      minWidth: 80,
    },
  },
};

const RoomVariantsSelect = ({ type }) => {
  const { t, i18n } = useTranslation();
  // const items = useSelector((state) => state.filter.value.pricePresets);
  const items = useSelector((state) => state.filter.value.roomsVariant);
  const location = useLocation();
  const query = useQuery();
  // const [selectedValue, setSelectedValue] = useState(["all"]);
  const [selectedValue, setSelectedValue] = useState([]);


  const search = useSearchMove(type);
  const handlerSelectChange = (event) => {

    const newValue = event.target.value
    const newValueArray= _.split(newValue, ',')
    if (newValue) {
      let overriddenData = { rooms: newValueArray.join("-") || "" };
      search.moveToFilterOverriddenWithUrlData(overriddenData);
    } else {
      if (items.length) {
        let overriddenData = { rooms: items[0].slug };
        search.moveToFilterOverriddenWithUrlData(overriddenData);
      }
    }
    setSelectedValue(newValue);
  };

  useEffect(()=>{

    const param = query.get('rooms');
    if (items && items.length && param) {
      const urlData=_.split(param, '-')

      const result=_.map(urlData, (item) => {
        let checkValue=item.trim()
        // debugger
        if(checkValue==="four"){
          checkValue='four+'
        }
        return items.find((e) => e.slug === checkValue)?.slug
      }).filter(e=>!_.isEmpty(e))
      setSelectedValue(result);
    }else {
      setSelectedValue([]);
    }
  },[location])
  useEffect(() => {
    // if (items.length) {
    //     const param = query.get('rooms');
    //     let filtered = items.find((e) => e.slug === param);
    //     if(!filtered) {
    //       filtered= _.first(items)
    //     }
    //     // debugger
    //   setSelectedValue(filtered.slug);
    // }
  }, [items]);

  return (
    <React.Fragment>
      {/*<FormControl fullWidth>*/}
        <CustomSelect
            autoWidth={true}
            labelId="select-room-variants"
            id="select-room-variants"
            value={selectedValue}
            multiple={true}
            MenuProps={CustomMenuProps} // Pass the custom MenuProps
            displayEmpty={true}
            onChange={handlerSelectChange}
            IconComponent={() => null} // Hides the icon
            style={{ padding: "2px" }} // Directly apply padding
            // sx={{
            //   "& .MuiSvgIcon-root": {
            //     display: "none", // Hide the icon
            //   },
            // }}
            renderValue={(selected) => {

              let result=_.map(selected, (item) => {
                return items.find((e) => e.slug === item)
              });
              // debugger
              if (result.length) {
                // debugger
                result=_.sortBy( result,'id').map(e=>e.label)
              }


              // debugger
                return result.length ? result.join(", ") : t("Кімнатність");
            }}

        >
          {/*<MenuItem value="" disabled>*/}
          {/*  Placeholder Text*/}
          {/*</MenuItem>*/}
          {/*<MenuItem  value="all" >{t('Будь-яка')}</MenuItem>*/}
          {items.map((item) => {
            return (
                <MenuItem key={item.id} value={item.slug}>{t(item.label)}</MenuItem>
            )
          }
          )}
        </CustomSelect>
      {/*</FormControl>*/}


      {/*<SelectItems*/}
      {/*  items={items}*/}
      {/*  selected={selectedItems}*/}
      {/*  label={t("Ціна")}*/}
      {/*  multiple={false}*/}
      {/*  callbackChange={handlerSelectChange}*/}
      {/*/>*/}
    </React.Fragment>
  );
};

export default RoomVariantsSelect;
