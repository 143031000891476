import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { getDefaultById } from "../../../utils/filterUtils";
// import { getDefaultById } from "/src/utils/filterUtils";
const SelectAutocomplete = (props) => {
  const [selectedValue, setSelectedValue] = useState(
    props.defaultValueId && props.options
      ? getDefaultById(props.defaultValueId, props.options)
      : null,
  );
  // const ref = useRef(null);
  const handlerChange = (e, value, reason) => {
    props.onChange(e, value, reason);
    setSelectedValue(value);
  };
  const handleFocus = (event) => {
    const input = event.target;
    setTimeout(() => {
      input.setSelectionRange(input.value.length, input.value.length); // Moves cursor to the end
    }, 0);
  };

  useEffect(() => {
    if (props.hasReset) {
      setSelectedValue(
        props.defaultValueId && props.options
          ? getDefaultById(props.defaultValueId, props.options)
          : null,
      );
    }
    // if (ref && ref.current) {
    //   ref.current.select = () => undefined;
    // }
  }, [props.hasReset]);
  if (!props.options || !props.options.length) return null;

  return (
    <React.Fragment>
      <div className="filter-fields-group__cell">
        <div className="filter-fields-group__cell-label">{props.title}</div>
        <div className="filter-fields-group__cell-input">
          <Autocomplete
            options={props.options}
            onChange={handlerChange}
            value={selectedValue}
            // onOpen={onClick}
            // ref={ref}
            renderInput={(params) => <TextField {...params }  onFocus={handleFocus}    />}
            // readOnly={props.readOnly }
          />
        </div>
      </div>
    </React.Fragment>
  );
};
// options =[{label: 'Львів',id:1,slug:'lviv'},...]
SelectAutocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  title: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  defaultValueId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  hasReset: PropTypes.number.isRequired,
};
SelectAutocomplete.defaultProps = {
  readOnly: true
};

export default SelectAutocomplete;
