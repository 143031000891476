import React, { useEffect, useState } from "react";

import {useSelector } from "react-redux";
import useSearchMove from "../../hooks/useSearchMove";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {useLocation} from "react-router-dom";
import {useQuery} from "../../hooks/useQuery";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {withStyles} from "@material-ui/core/styles";
import Autocomplete from "@mui/material/Autocomplete";


const CustomSelect = withStyles((theme)=> ({
  root: {
    "& .MuiSelect-select": {
      padding: "10px", // Custom padding
      paddingRight  : "10px!important", // Custom padding
      paddingLeft  : "10px", // Custom padding
      fontSize: "0.85rem", // Custom font-size for select-option
    },
    "& .MuiSvgIcon-root": {
      display: "none",
    },
    ".MuiSelect-root":{
      minWidth: '90%'
    },
  },

}))(Select);


const PriceSelectV2 = ({ type }) => {
  const { t, i18n } = useTranslation();
  const items = useSelector((state) => state.filter.value.pricePresets);
  const location = useLocation();
  const query = useQuery();
  const [selectedValue, setSelectedValue] = useState(null);


  const search = useSearchMove(type);
  const handlerSelectChange = (event) => {

    const newValue = event.target.value
    if (newValue) {
      let overriddenData = { price: newValue || "" };
      search.moveToFilterOverriddenWithUrlData(overriddenData);
    } else {
      if (items.length) {
        let overriddenData = { price: items[0].slug };
        search.moveToFilterOverriddenWithUrlData(overriddenData);
      }
    }
    setSelectedValue(newValue);
  };

  useEffect(()=>{

    const param = query.get('price');
    if (items && items.length && param) {
      let filtered = items.find((e) => e.slug === param);
      if(!filtered) {
        filtered= _.first(items)
      }
      // debugger
      // setSelectedItems(filtered);
      setSelectedValue(filtered.slug);
    }
  },[location])
  useEffect(() => {
    if (items.length) {
        const param = query.get('price');
        let filtered = items.find((e) => e.slug === param);
        if(!filtered) {
          filtered= _.first(items)
        }
        // debugger
        // setSelectedItems(filtered);
      setSelectedValue(filtered.slug);
    }
  }, [items]);

  return (
    <React.Fragment>
      {/*<FormControl fullWidth>*/}
      {/*  <InputLabel id="select-label-price">{t("Ціна")}</InputLabel>*/}
        <CustomSelect
            autoWidth={true}
            labelId="select-label-price"
            id="select-price"
            value={selectedValue}
            IconComponent={() => null} // Hides the icon
            style={{ padding: "2px" }} // Directly apply padding

            // sx={{
            //   "& .MuiSvgIcon-root": {
            //     display: "none", // Hide the icon
            //   },
            // }}
            // sx={{
            //   width: "auto", // Set width to auto
            // }}
            // label={t("Ціна")}
            onChange={handlerSelectChange}

        >
          {items.map((item) => {
            return (
                <MenuItem key={item.id} value={item.slug}>{t(item.label)}</MenuItem>
            )
          }
          )}
        </CustomSelect>
      {/*</FormControl>*/}


      {/*<SelectItems*/}
      {/*  items={items}*/}
      {/*  selected={selectedItems}*/}
      {/*  label={t("Ціна")}*/}
      {/*  multiple={false}*/}
      {/*  callbackChange={handlerSelectChange}*/}
      {/*/>*/}
    </React.Fragment>
  );
};

export default PriceSelectV2;
